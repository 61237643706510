import { InsuranceEntitlements } from './Entitlements/Insurance';

export interface UserAttributes {
  ['app.hasChildren']?: boolean | undefined;
  ['app.hasDomesticPartnership']?: boolean | undefined ;
  ['app.hasSpouse']?: boolean | undefined;
  ['app.hasUnderageChildren']?: boolean | undefined;

  ['app.viewedHeirsVideo']?: boolean;
  ['app.viewedTrusteeVideo']?: boolean;
  ['app.viewedGuardiansVideo']?: boolean;

  ['app.willNeedsReview']?: boolean;
  ['app.willIntroFinished']?: boolean;
  ['app.willHasConfirmedBasics']?: boolean;
  ['app.willHeirsChapterStarted']?: boolean | undefined;
  ['app.willHeirsChapterNeedsReview']?: boolean;
  ['app.guardiansChapterStarted']?: boolean | undefined;
  ['app.guardiansChapterNeedsReview']?: boolean;
  ['app.viewedValuablesVideo']?: boolean;
  ['app.willValuablesFinished']?: boolean | undefined;
  ['app.petsChapterStarted']?: boolean | undefined;
  ['app.willRealEstateFinished']?: boolean | undefined;
  ['app.executorsChapterStarted']?: boolean | undefined;
  ['app.viewedExecutorsVideo']?: boolean;
  ['app.willPreviewViewed']?: boolean | undefined;
  ['app.viewedFinishedWillVideo']?: boolean;
  ['app.viewedFinishedWillUpsell']?: boolean;


  ['app.trustHasConfirmedBasics']?: boolean;
  ['app.trustNeedsReview']?: boolean;
  ['app.viewedTrustIntroVideo']?: boolean;
  ['app.viewedTrustAccountsVideo']?: boolean;
  ['app.trusteesChapterStarted']?: boolean;
  ['app.trusteeBackupReminderAlertViewed']?: boolean;
  ['app.trustHasDelayedPayment']?: boolean;
  ['app.trustHasSignedPourOverWill']?: boolean;
  ['app.trustHasSignedTrust']?: boolean;
  ['app.trustHeirsChapterStarted']?: boolean;
  ['app.trustPreviewViewed']?: boolean;
  ['app.trustSignedPourOverWillDate']?: string;
  ['app.trustSignedTrustDate']?: string;
  ['app.trustWrapUpVideoViewed']?: boolean;
  ['app.hasSeenTrusteesInviteModal']?: boolean;

  ['financial.networth']?: number;
  ['app.viewedPourOverWillVideo']?: boolean;

  ['app.disability.hasStartedIntroduction']?: boolean | undefined;
  ['app.disability.hasCompletedCoverage']?: boolean | undefined;
  ['app.disability.hasCompletedApplication']?: boolean | undefined;
  ['app.disability.hasCompletedPayment']?: boolean | undefined;
  ['app.disability.hasCompletedConsent']?: boolean | undefined;
  ['financial.income.below_threshold']?: boolean | undefined;
  ['app.financial.income.annual']?: number | undefined;
  ['app.hasSeenChildrenHeirsLifeModal']?: boolean | undefined;

  ['app.hasSeenExecutorsInviteModal']?: boolean;
  ['app.hasSeenGuardiansInviteModal']?: boolean;

  ['app.poaDocumentStarted']?: boolean;
  ['app.poaAddressConfirmed']?: boolean;
  ['app.attorneyChapterStarted']?: boolean;
  ['app.powersChapterStarted']?: boolean;
  ['app.powersChapterFinished']?: boolean;
  ['app.instructionsChapterStarted']?: boolean;
  ['app.instructionsChapterFinished']?: boolean;
  ['app.poaDocumentViewed']?: boolean;

  ['app.healthcareDirectiveDocumentStarted']?: boolean;
  ['app.healthcareDirectiveAddressConfirmed']?: boolean;
  ['app.healthcareDirectiveAgentChapterStarted']?: boolean;
  ['app.healthcareDirectiveWishesChapterStarted']?: boolean;
  ['app.healthcareDirectiveWishesChapterFinished']?: boolean;
  ['app.healthcareDirectiveDocumentViewed']?: boolean;

  ['app.hasGeneratedSpousePerkCode']?: boolean;
}

type EntitlementStatus = 'enabled' | 'disabled' | 'unavailable';

export interface ProductSku {
  id: string;
  sku: string;
  platform: 'apple'|'google'|'stripe'|'internal';
}

export type EntitlementRestrictions = string[];
export interface EntitlementsUpgrade {
  id: string;
  type: 'PRODUCT'|'SUBSCRIPTION';
  name: string;
  price: number;
  subscription_length: string;
  skus: ProductSku[];
}

export interface DocumentEntitlement {
  isSupported: boolean;
  feature: EntitlementStatus;
  upgradeable: EntitlementStatus;
  upgrades: EntitlementsUpgrade[];
  restrictions: EntitlementRestrictions;
}

interface WillDocumentEntitlement extends DocumentEntitlement {
  pourover: EntitlementStatus;
}

interface TrustDocumentEntitlement extends DocumentEntitlement {
  joint: EntitlementStatus;
}

interface PartnerCode {
  id: string;
  code: string;
}

export interface UserEntitlements {
  documents: {
    will: WillDocumentEntitlement;
    trust: TrustDocumentEntitlement;
    medical_consent: DocumentEntitlement;
    healthcare_directive: DocumentEntitlement;
    power_of_attorney: DocumentEntitlement;
  };
  insurance: InsuranceEntitlements;
}

export enum ExternalSource{
  STRIPE = 'stripe',
  BLACKROCK = 'blackrock',
  ETHOS = 'Ethos_life',
}

interface ExternalIdentifier {
  id: string;
  user_id: string;
  source: ExternalSource;
  account_id: string;
  flow: string;
  will_and_trust_uuid: string;
}

interface UserInterface {
  id: string;
  phone?: string;
  email?: string;
  referral_id?: string;
  person_id?: string; // UUID pointer
  attributes: UserAttributes;
  entitlements: UserEntitlements;
  status: 'GUEST'|'PENDING'|'ACTIVE'|'DELETED';
  partners: PartnerCode[];
  created_at: string;
  externalIdentifier?: ExternalIdentifier;
}


export default UserInterface;
