import { FC, useEffect } from 'react';
import { Router, Route, useLocation, Switch, Redirect } from 'react-router-dom';

// Utils
import history from '../../core/history';
import analytics from '../utils/analytics';
import FacebookPixel from '../providers/FacebookPixelProvider';

// Routes
import {
  appStore,
  heir,
  will_heirs,
  home,
  login,
  people,
  person,
  properties,
  profile,
  will_realEstate,
  signup,
  will_valuables,
  will,
  will_basics,
  will_intro,
  will_review,
  trust,
  trust_basics,
  trust_review,
  trust_funding,
  trustees,
  will_guardians,
  lifeInsurance,
  whatIsTomorrow,
  iosLicenseInfo,
  iosLegalDisclaimer,
  iosTermsOfService,
  resetPassword,
  emailVerification,
  testErrorBoundary,
  blackRockLanding,
  disability,
  disability_introduction,
  disability_login,
  disability_login_code, disability_welcome, disability_coverage,
  disability_application,
  pourover_will,
  inviteAccepted,
  invite,
  sendInvites,
  disability_payment,
  trust_heirs,
  accounts,
  checkout,
  poa,
  poa_intro,
  poa_attorney_in_fact,
  poa_powers,
  poa_review,
  poa_special_instructions,
  will_pets,
  will_executors,
  dashboard,
  healthcare_directive,
  healthcare_directive_intro,
  healthcare_directive_address,
  healthcare_directive_agent,
  healthcare_directive_wishes,
  healthcare_directive_review,
  medical_consent,
  medical_consent_document,
  medical_consent_care_info,
  medical_consent_caretaker,
  medical_consent_address,
  medical_consent_medications,
  medical_consent_allergies,
  medical_consent_doctors,
} from '../../core/routes';

import AuthenticatedRoute from './AuthenticatedRoute';

// Utils
import { useBSTracker } from '../hooks/useBackstack';
import useFetchData from '../hooks/useFetchData';
import { useSelector } from '../store';
import appConfig from '../../core/appConfig';
import { ENV_PRODUCTION } from '../../core/constants';

// Views
import Dashboard from '../views/Dashboard';
import EmailVerification from '../views/Profile/EmailVerification';
import ErrorBoundary, { TestErrorBoundary } from '../views/ErrorBoundary';
import FourOhFour from '../views/FourOhFour';
import InviteLanding from '../views/InviteLanding';
import Home from '../views/Home';
import InheritanceShareForm from '../views/Heirs/InheritanceShareForm';
import InviteAccepted from '../views/Invites/InviteAccepted';
import InvitePage from '../views/Invites/InvitesPage';
import IosPrompt from '../views/IosPrompt';
import LegalDisclaimer from '../views/Info/LegalDisclaimer';
import LicenseInfo from '../views/Info/LicenseInfo';
import LifeInsurance from '../views/LifeInsurance';
import Loading from '../views/Loading';
import Onboarding from '../views/Onboarding';
import PasswordReset from '../views/Profile/PasswordReset';
import PeopleView from '../views/People';
import PersonDetailView from '../views/People/PersonDetailView';
import Profile from '../views/Profile';
import Property from '../views/Property';
import Snackbar from '../components/Snackbar';
import TermsOfService from '../views/Info/TermsOfService';
import WhatIsTomorrow from '../views/Video/WhatIsTomorrow';
import ModalController from '../components/ModalController';
import DevOnlyUserAttributesModal from '../views/DevOnly/DevOnlyUserAttributesModal';
import BlackRockLandingPage from '../views/Lenox/BlackRockLandingPage';
import { withPersonDetailParam } from '../views/People/PersonDetailParamWrapper';

// Templates
import DefaultLayout from '../templates/DefaultLayout';

// Routers
import WillRouter from './documents/will';
import TrustRouter from './documents/trust';
import PoaRouter from './documents/poa';
import DirectiveRouter from './documents/directive';
import MedicalConsentRouter from './documents/medicalConsent';
import CheckoutRouter from './checkout';

// Hooks
import useAuth from '../hooks/useAuth';
import { PaymentSucceeded } from './payment-redirect/PaymentRedirectLogic';
import { PaymentCancelled } from './payment-redirect/PaymentRedirectLogic';

/**
 * should be child of <Router/>
 */
const TrackPageViews: FC = ({ children }) => {
  const location = useLocation();
  const path = `${location.pathname}?${location.search}`;

  // Track history changes on `backstack` store
  useBSTracker();

  // Report history changes to analytics via segment
  useEffect(() => {
    analytics.page();
    FacebookPixel.pageView();
  }, [ path ]);

  return <>{children}</>;
};

/**
 * MainAppRouter - main router component
 */
export const MainAppRouter: FC = () => {
  useFetchData();
  const { isGuestUser } = useAuth();
  const isLoading = useSelector( state => state.isLoading );

  const everyValidPath: string[] = [
    accounts,
    appStore,
    checkout,
    dashboard,
    will_executors,
    heir,
    home,
    inviteAccepted,
    invite,
    sendInvites,
    login,
    people,
    person,
    pourover_will,
    properties,
    profile,
    will_realEstate,
    signup,
    trust_funding,
    will_valuables,
    will,
    will_basics,
    will_intro,
    will_review,
    will_heirs,
    will_pets,
    trust,
    trust_basics,
    trust_review,
    trustees,
    trust_heirs,
    will_guardians,
    lifeInsurance,
    whatIsTomorrow,
    iosLicenseInfo,
    iosLegalDisclaimer,
    iosTermsOfService,
    resetPassword,
    emailVerification,
    blackRockLanding,
    disability_login,
    disability_login_code,
    disability_welcome,
    disability,
    disability_introduction,
    disability_coverage,
    disability_application,
    disability_payment,
    poa,
    poa_intro,
    poa_attorney_in_fact,
    poa_powers,
    poa_special_instructions,
    poa_review,
    healthcare_directive_intro,
    healthcare_directive_address,
    healthcare_directive,
    healthcare_directive_agent,
    healthcare_directive_wishes,
    healthcare_directive_review,
    medical_consent,
    medical_consent_document,
    medical_consent_care_info,
    medical_consent_caretaker,
    medical_consent_address,
    medical_consent_medications,
    medical_consent_allergies,
    medical_consent_doctors,
  ].map( route => route.path );

  // Default Layout Routes
  // @TODO - I don't love this solution
  const defaultLayoutRoutes: string[] = [
    accounts,
    dashboard,
    will_executors,
    people,
    pourover_will,
    properties,
    will_realEstate,
    will_valuables,
    `${will_realEstate.get()}/create`,
    `${will_valuables.get()}/create`,
    will,
    will_basics,
    will_intro,
    will_review,
    will_heirs,
    will_pets,
    trust,
    trust_basics,
    trust_review,
    trust_funding,
    trustees,
    trust_heirs,
    will_guardians,
    lifeInsurance,
    resetPassword,
    emailVerification,
    blackRockLanding,
    disability_login,
    disability_login_code,
    disability_welcome,
    disability,
    disability_introduction,
    disability_coverage,
    disability_application,
    disability_payment,
    poa,
    poa_intro,
    poa_attorney_in_fact,
    poa_powers,
    poa_special_instructions,
    poa_review,
    healthcare_directive_intro,
    healthcare_directive_address,
    healthcare_directive,
    healthcare_directive_agent,
    healthcare_directive_wishes,
    healthcare_directive_review,
    medical_consent,
    medical_consent_document,
    medical_consent_care_info,
    medical_consent_caretaker,
    medical_consent_address,
    medical_consent_medications,
    medical_consent_allergies,
    medical_consent_doctors,
  ].map( route => route.path );

  const PersonDetailViewWithParam = withPersonDetailParam( PersonDetailView );

  return (
    <Router history={ history }>
      <TrackPageViews>

        {/*
          This Switch statement should have 2 children
          - a route that matches all valid paths in the application
          - 404 Not Found fallback page
          - @todo refactor `routes` module and compute `everyValidPath` array
        */}
        <Switch>
          {/*
          Payment Redirect for Trial users
          Required: pass back the state which is nonce uuid
          which will be compared back to our LS
          */}
          <Route exact path={"/payment-succeeded"}>
            <PaymentSucceeded />
          </Route>
          <Route exact path={"/payment-cancelled"}>
            <PaymentCancelled />
          </Route>

          {isLoading
            ? <Loading/>
            : <Route exact path={ everyValidPath }>
              <ErrorBoundary>
                <DefaultLayout acceptedRoutes={ defaultLayoutRoutes }>

                  <Route exact path={ dashboard.path } component={ Dashboard }/>

                  {/* Documents */}
                  <Route path={ will.path } component={ WillRouter } />
                  <Route path={ trust.path } component={ TrustRouter } />
                  <Route path={ poa.path } component={ PoaRouter } />
                  <Route path={ healthcare_directive.path } component={ DirectiveRouter } />
                  <Route path={ medical_consent.path } component={ MedicalConsentRouter } />

                  <AuthenticatedRoute exact path={ people.path } component={ PeopleView }/>
                  <Route exact path={ resetPassword.path } component={ PasswordReset }/>
                  <Route exact path={ emailVerification.path } component={ EmailVerification }/>

                </DefaultLayout>

                <Route exact path={ home.path }>
                  {isGuestUser ? <Home /> : <Redirect to={ dashboard.path } />}
                </Route>
                <Route exact path={ [ login.path, signup.path ] } component={ Onboarding }/>
                <Route exact path={ checkout.path } component={ CheckoutRouter } />
                <Route exact path={ lifeInsurance.path } component={ LifeInsurance }/>
                <AuthenticatedRoute path={ properties.path } component={ Property }/>
                <Route exact path={ invite.path } component={ InviteLanding } />
                <Route exact path={ sendInvites.path } component={ InvitePage } />
                <Route exact path={ blackRockLanding.path } component={ BlackRockLandingPage }/>

                {/* Modal views */}
                <AuthenticatedRoute exact path={ profile.path } component={ Profile }/>
                <AuthenticatedRoute exact path={ person.path }>
                  <PersonDetailViewWithParam/>
                </AuthenticatedRoute>
                <AuthenticatedRoute exact path={ heir.path } component={ InheritanceShareForm }/>
                <Route exact path={ whatIsTomorrow.path } component={ WhatIsTomorrow }/>
                <Route exact path={ appStore.path } component={ IosPrompt }/>
                <AuthenticatedRoute exact path={ inviteAccepted.path } component={ InviteAccepted }/>

                {/* Always rendered */}
                <Snackbar/>
                <ModalController />

                {/* User attributes menu */}
                { appConfig.app.deploymentEnv !== ENV_PRODUCTION && <DevOnlyUserAttributesModal /> }

                {/* Static pages */}
                <Route exact path={ iosLicenseInfo.path }><LicenseInfo isFromIOS/></Route>
                <Route exact path={ iosLegalDisclaimer.path }><LegalDisclaimer isFromIOS/></Route>
                <Route exact path={ iosTermsOfService.path }><TermsOfService isFromIOS/></Route>
              </ErrorBoundary>
            </Route>
          }

          <Route exact path={ testErrorBoundary.path }>
            <TestErrorBoundary/>
          </Route>

          {/*
            404 Not Found
            IMPORTANT - This needs to go last
          */}
          <Route>
            <DefaultLayout>
              <FourOhFour/>
            </DefaultLayout>
          </Route>
        </Switch>
      </TrackPageViews>
    </Router>
  );

};


export default MainAppRouter;
