import { AxiosError } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Document from '../../core-data-service/models/Document';

// Utils
import WebClientRequest from '../../core-data-service/WebClientRequest';
import DocumentInterface, { AxiosDocumentResponse, AxiosDocumentsResponse } from '../../types/DocumentInterface';

// Data
import initialState from '../initial/documents';

interface IFetchDocuments {
  onSuccess?: ( response: AxiosDocumentsResponse )=> void;
}

export interface IPostDocument {
  type: Document;
  onSuccess?: ( response: AxiosDocumentResponse )=> void;
  onError?: ( response: AxiosError )=> void;
  recipients?: string[];
}


export const fetchDocuments = createAsyncThunk(
  'documents/fetchDocuments',
  async( arg: IFetchDocuments | void, thunkAPI ) => {
    const url = '/v3/documents';
    return WebClientRequest
      .get( url )
      .then(( response: AxiosDocumentsResponse ) => {
        thunkAPI.dispatch( updateDocuments( response.data.data ));
        arg && arg.onSuccess && arg.onSuccess( response );
      });
  },
);


export const postDocument = createAsyncThunk(
  'documents/postDocument',
  async({ onSuccess, onError, ...data }: IPostDocument, thunkAPI ) => {

    const url = '/v3/documents';
    return WebClientRequest
      .post( url, data )
      .then(( response: AxiosDocumentResponse ) => {
        thunkAPI.dispatch( updateDocument( response.data.data ));
        onSuccess && onSuccess( response );
      }).catch( e => {
        onError && onError( e );
      });
  },
);

const documentsSlice = createSlice({
  name: 'documents',
  initialState,

  reducers: {

    updateDocuments( documents, action: PayloadAction<DocumentInterface[]> ){
      if( documents.data.length === 0 ){
        documents.data = action.payload;
      } else {
        action.payload.forEach( document => {
          const idx = documents.data.findIndex( xDoc => xDoc.id === document.id );
          if( idx >= 0 ){
            documents.data[idx] = document;
          } else {
            documents.data.push( document );
          }
        });
      }
    },

    updateDocument( documents, action: PayloadAction<DocumentInterface> ){
      const payload = action.payload;
      const idx = documents.data.findIndex( document => document.id === payload.id );
      if( idx >= 0 ){
        documents.data[idx] = payload;
      } else {
        documents.data.push( payload );
      }
    },
  },

  extraReducers : builder => {
    // fetchDocuments.<status>
    builder.addCase( fetchDocuments.pending, ( documents, action ) => {
      const { arg, ...meta } = action.meta;
      documents.fetchDocuments.meta = meta;
    });
    builder.addCase( fetchDocuments.fulfilled, ( documents, action ) => {
      const { arg, ...meta } = action.meta;
      documents.fetchDocuments.meta = meta;
    });
    builder.addCase( fetchDocuments.rejected, ( documents, action ) => {
      const { arg, ...meta } = action.meta;
      documents.fetchDocuments.meta = meta;
    });

    // postDocument.<status>
    builder.addCase( postDocument.pending, ( documents, action ) => {
      const { arg, ...meta } = action.meta;
      documents.postDocument.meta = meta;
    });
    builder.addCase( postDocument.fulfilled, ( documents, action ) => {
      const { arg, ...meta } = action.meta;
      documents.postDocument.meta = meta;
    });
    builder.addCase( postDocument.rejected, ( documents, action ) => {
      const { arg, ...meta } = action.meta;
      documents.postDocument.meta = meta;
    });
  },

});


export const { updateDocuments, updateDocument } = documentsSlice.actions;

export default documentsSlice.reducer;
