// @ts-nocheck
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useEffect, useRef, useState } from 'react';

interface PDFViewerProps {
  data: Buffer;
  loader: React.ReactNode;
  className?: string;
  canvasClassName?: string;
  maxPages?: number;
}

function PDFViewer({ className, loader, data, maxPages ,canvasClassName }: PDFViewerProps ){
  const pdfDoc = useRef<any>( null );
  const viewer = useRef<HTMLDivElement | null>( null );
  const [ loading, setLoading ] = useState( true );
  useEffect(() => {
    function init(){
      if ( !window.pdfjsLib ) {
        return setTimeout( init, 300 );
      }
      window.pdfjsLib.GlobalWorkerOptions.workerSrc =
        '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.js';
      const canvasList: HTMLCanvasElement[] = [];
      function renderPage( pageNumber: number, canvas: HTMLCanvasElement ){
        pdfDoc.current!.getPage( pageNumber ).then( page => {
          const viewport = page.getViewport({
            scale: window.devicePixelRatio * 1.5,
          });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          canvas.style.width = `${Math.max(
            320,
            viewer.current?.clientWidth! - 24,
          )}px`;
          if( canvasClassName ) {
            canvas.classList.add( canvasClassName );
          }
          page.render({
            canvasContext: canvas.getContext( '2d' )!,
            viewport: viewport,
          });
        });
      }
      window.pdfjsLib
        .getDocument({ data, withCredentials: false })
        .promise.then( doc => {
          pdfDoc.current = doc;
          const totalPages = doc.numPages;
          const allPages = maxPages
            ? Math.min( maxPages, totalPages )
            : totalPages;
          for ( let page = 1; page <= allPages; page++ ) {
            const canvas = document.createElement( 'canvas' );
            viewer.current?.appendChild( canvas );
            canvasList.push( canvas );
            renderPage( page, canvas );
          }
          setLoading( false );
        });
      return () => {
        canvasList.forEach( c => viewer.current?.removeChild( c ));
      };
    }
    init();
  }, []);
  return (
    <div className={ !loading ? className : '' } ref={ viewer }>
      {loading && loader}
    </div>
  );
}

export default PDFViewer;
