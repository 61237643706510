import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { gTagInit } from './client/providers/GTagProvider';

/**
 * App client modules
 */
import MainAppRouter from './client/router';
import reportWebVitals from './client/reportWebVitals';
import './client/styles/base.css';

/**
 * App core modules
 */
import store from './client/store';
import { setupOptimizely } from './client/utils/optimizely/setup';

localStorage.setItem( 'isOnboarding', JSON.stringify( false ));

ReactDOM.render(
  <Provider store={ store }>
    <MainAppRouter />
  </Provider>,
  document.getElementById( 'root' ),
);

gTagInit();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

setupOptimizely();
