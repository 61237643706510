import { ReactElement, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  clearPaymentRedirectState,
  getCurrentPaymentRedirectState,
} from '../../hooks/useFinishTrial';
import Loading from '../../views/Loading';
import analytics from '../../utils/analytics';

function PaymentRedirectLogic({
  eventName,
}: {
  eventName: 'payment_succeeded' | 'payment_cancelled';
}): ReactElement{
  const { state, originalDocumentUrl } = getCurrentPaymentRedirectState();
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams( search );
  const queryState = params.get( 'state' );
  const isSuccess = originalDocumentUrl && state === queryState;
  useEffect(() => {
    // only run it once
    analytics.track( eventName, {
      status: isSuccess ? 'success_to_redirect' : 'failure_to_redirect',
      state,
      queryState,
      currentUrl: window.location.href,
      originalDocumentUrl,
    });
    if ( isSuccess ) {
      clearPaymentRedirectState();
      const url = new URL( originalDocumentUrl );
      history.replace( url.pathname + url.search );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Loading />;
}

export const PaymentSucceeded = (): ReactElement => (
  <PaymentRedirectLogic eventName="payment_succeeded" />
);

export const PaymentCancelled = (): ReactElement => (
  <PaymentRedirectLogic eventName="payment_cancelled" />
);
