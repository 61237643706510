import { useEffect, useState } from 'react';
import WebClientRequest from '../core-data-service/WebClientRequest';

interface UsePreviewPDFData {
  base64: string | null;
  error: string | null;
  isLoading: boolean;
}

function usePreviewPDF( documentId: string ): UsePreviewPDFData{
  const [ isLoading, setLoading ] = useState( false );
  const [ error, setError ] = useState<string | null>( null );
  const [ base64, setBase64 ] = useState<string | null>( null );
  useEffect(() => {
    setLoading( true );
    WebClientRequest.get( `/v3/documents/${documentId}/preview` )
      .then( response => {
        setBase64( response.data );
      })
      .catch( e => {
        setError( e );
      })
      .finally(() => setLoading( false ));
  }, []);

  return { base64, isLoading, error };
}

export default usePreviewPDF;
