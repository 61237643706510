import optimizely, {
  Client,
  ListenerPayload,
  OptimizelyDecision,
  UserAttributes,
} from '@optimizely/optimizely-sdk';
import appConfig from '../../../core/appConfig';
import analytics from '../analytics';
import log from '../log';

type OptimizelyClient = Client;

export let optimizelyClient: OptimizelyClient | null = null;

export const getDecision = ({
  featureKey,
  userId,
  attributes,
}: {
  featureKey: string;
  userId: string;
  attributes: UserAttributes;
}): OptimizelyDecision | null | undefined => {
  if ( !optimizelyClient ) {
    return {
      enabled: false,
    } as OptimizelyDecision;
  }
  const user = optimizelyClient.createUserContext( userId, attributes );
  if ( !user ) {
    return {
      enabled: false,
    } as OptimizelyDecision;
  }
  const decision = user.decide( featureKey );
  return decision;
};

export const setupOptimizely = async(): Promise<
  OptimizelyClient | undefined
> => {
  if ( optimizelyClient ) {
    return optimizelyClient;
  }

  optimizelyClient = optimizely.createInstance({
    sdkKey: appConfig.optimizely.sdkKey,
    datafileOptions: {
      autoUpdate: true,
    },
  });

  if ( !optimizelyClient ) {
    log.error( 'Failed to create optimizely client' );
    return;
  }

  registerListeners( optimizelyClient );
};

const activationList: Record<string,boolean> = {};

const registerListeners = ( optimizelyClient: OptimizelyClient ) => {
  optimizelyClient.notificationCenter.addNotificationListener<
    ListenerPayload & {
      type: 'flag' | string;
      decisionInfo: {
        flagKey: string;
        enabled: boolean;
        variationKey: boolean;
      };
    }
  >(
    optimizely.enums.NOTIFICATION_TYPES.DECISION,
    ({ type, userId, attributes, decisionInfo }) => {
      if ( type !== 'flag' ) {
        return;
      }
      if ( activationList[ decisionInfo.flagKey ]) {
        return;
      }
      activationList[ decisionInfo.flagKey ] = decisionInfo.variationKey;
      analytics.track( 'Experiment Activated', {
        type: type,
        optimizelyUserId: userId,
        attributes,
        enabled: decisionInfo.enabled,
        flagKey: decisionInfo.flagKey,
        variationKey: decisionInfo.variationKey,
      });
    },
  );
};
