import { Keyable } from './../types/Keyable';
import { useRef, useState, useCallback } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

// Redux
import { makeSnack } from '../store/reducers/snackbarReducer';
import { postDocument } from '../store/reducers/documentsReducer';
import { useDispatch, useSelector } from '../store';
import userSelector from '../store/selectors/userSelector';

// Models
import Document from '../core-data-service/models/Document';

// Utils
import analytics from '../utils/analytics';

interface IEmailDocument {
  emails?: string[];
  onSuccess?: ()=> void;
}

interface IUseDocumentPDFReturn {
  previewModeId?: string|null;
  onSuccessTrial: ()=> void;
  openDocument: ()=> void;
  emailDocument: ({ emails, onSuccess }?: IEmailDocument )=> void;
  hasErrorFetchingDocument: boolean;
  isFetchingDocument: boolean;
}

const useDocumentPDF = ( documentType: Document, data?: Keyable, hasSeenView?: boolean ): IUseDocumentPDFReturn => {
  const dispatch = useDispatch();
  const documentLink = useRef<string>();
  const [ hasError, setHasError ] = useState<boolean>( false );
  const [ previewModeId, setPreviewModeId ] = useState<string|null>( null );
  const [ isFetchingDocument, setIsFetchingDocument ] = useState<boolean>( false );

  const { email } = useSelector( userSelector );
  const userEmails = email ? [ email ] : undefined;

  const fetchDocument = useCallback(
    ( emails?: string[], onSuccess?: ()=> void ) => {
      setIsFetchingDocument( true );
      dispatch( postDocument({
        type: documentType,
        recipients: emails,
        ...data,
        onSuccess: ( response: AxiosResponse ) => {
          const url = response.data.data?.url;
          setPreviewModeId(response.data.data?.preview_only ? response.data.data?.id : null );
          documentLink.current = url ;
          setIsFetchingDocument( false );
          onSuccess && onSuccess();
        },
        onError: ( error: AxiosError ) => {
          dispatch( makeSnack({ message: 'There has been an error fetching your document please refresh your browser.', theme: 'error' }));
          setHasError( true );
          setIsFetchingDocument( false );
        },
      }));
    },
    [ dispatch, documentType, data ],
  );

  const openDocument = ( link: string | undefined ) => {
    analytics.track( `ClickedPreview${documentType}` );
    if( link ) {
      window.open( link, '_blank' );
    }
  };

  if( !documentLink.current && !isFetchingDocument && !hasError ) {
    fetchDocument();
  }

  return {
    previewModeId,
    onSuccessTrial: () => setPreviewModeId(null),
    openDocument: () => openDocument( documentLink.current ),
    emailDocument: ({ emails, onSuccess } = {}) => fetchDocument( emails || userEmails, onSuccess ),
    hasErrorFetchingDocument: hasError,
    isFetchingDocument,
  };
};
export default useDocumentPDF;
