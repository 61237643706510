import { ReactElement } from 'react';

// Components
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import PDFViewer from './PDFViewer';
import LoadingSpinner from '../../components/LoadingSpinner';
import usePreviewPDF from '../../hooks/usePreviewPDF';
import useFinishTrial from '../../hooks/useFinishTrial';
import Icon from '../../components/Icon';

// Hooks

// Models

// Redux

// Routes

// Utils

interface DocumentPreviewProps {
  documentId: string;
  documentTitle: string
  onSuccess: ()=> void;
  onBack: ()=> void;
}

const COPYS = [
  'Your custom document is ready for download — to access the full document now, finish your trial and pay.',
  'Ensure your family and assets are protected.',
  'Pay once — lifetime access and unlimited updates are included with your purchase.',
];

function PaymentSuccessfulActionSheet({ onClick }: { onClick: ()=> void }){
  return (
    <>
      <div className="flex flex-row items-start justify-between w-full">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#DCFAE6" />
          <rect
            x="4"
            y="4"
            width="48"
            height="48"
            rx="24"
            stroke="#ECFDF3"
            stroke-width="8"
          />
          <path
            d="M27 23H29V25H27V23ZM27 27H29V33H27V27ZM28 18C22.48 18 18 22.48 18 28C18 33.52 22.48 38 28 38C33.52 38 38 33.52 38 28C38 22.48 33.52 18 28 18ZM28 36C23.59 36 20 32.41 20 28C20 23.59 23.59 20 28 20C32.41 20 36 23.59 36 28C36 32.41 32.41 36 28 36Z"
            fill="#056257"
          />
        </svg>
        <Icon className="-mt-1" name="close" onClick={ () => onClick() } />
      </div>
      <h4 className="text-md text-black self-start">Payment Successful!</h4>
      <span className="text-sm text-[#525252] self-start">
        Now you have full access to all your documents.
      </span>
      <Button
        type="button"
        className="w-full !rounded-md !py-4"
        id="payment-complete-action"
        onClick={ () => {
          onClick();
        } }
      >
        Great, continue
      </Button>
    </>
  );
}

export default function DocumentPreview({
  documentId,
  documentTitle,
  onBack,
  onSuccess,
}: DocumentPreviewProps ): ReactElement{
  const { base64, isLoading } = usePreviewPDF( documentId );
  const finishTrialActions = useFinishTrial();
  return (
    <>
      <Modal
        contentClassName="!bg-moss !w-full !p-0"
        onClose={ finishTrialActions.isSuccess ? onSuccess : onBack }
        propsOverlayStyle={{ overflow: 'hidden' }}
      >
        <div className="flex flex-col overflow-x-hidden overflow-y-hidden h-full sm-only:max-h-[calc(100vh-48px)] max-h-[90vh]">
          <div
            style={{
              filter: finishTrialActions.isSuccess ? 'blur(4px)' : '',
            }}
            className="flex flex-col flex-1 items-center justify-start gap-4 py-8 max-w-[100vw] overflow-x-hidden overflow-y-auto"
          >
            <h1
              id="modal-title"
              className="text-3xl font-headline text-black text-center mb-4 sm-only:mb-6 px-8"
            >
              Your {documentTitle} is Ready — Just One Step Left
            </h1>

            <ul className="list-disc flex flex-col gap-2 py-4 text-[#525252] px-8 flex-shrink-0">
              {COPYS.map(( copy, idx ) => (
                <li className="py-2" key={ idx }>
                  {copy}
                </li>
              ))}
            </ul>

            {isLoading || !base64 ? (
              <LoadingSpinner position="default" />
            ) : (
              <PDFViewer
                className="mx-10 flex flex-col overflow-x-hidden overflow-y m-auto max-w-[100vw] gap-4 flex-shrink-0 overflow-y-hidden"
                canvasClassName="rounded-sm"
                data={ Buffer.from( base64, 'base64' ) }
                maxPages={ 3 }
                loader={ <LoadingSpinner position="default" /> }
              />
            )}
          </div>
          {/* Mobile CTA container */}
          <div
            style={{
              boxShadow: '0px -4px 8px 0px #0000001A',
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            }}
            className={ `flex-grow-0 bg-white h-[122px] p-[24px]
              flex flex-col items-center justify-center gap-4
              ${finishTrialActions.isSuccess ? 'h-[260px] items-start' : ''}
              ` }
          >
            {finishTrialActions.isSuccess ? (
              <PaymentSuccessfulActionSheet onClick={ onSuccess } />
            ) : (
              <>
                <Button
                  type="button"
                  className="w-min px-6 bg-[#272727] hover:bg-[#272727] !rounded-md !py-4"
                  id="finish-trial"
                  isPending={ finishTrialActions.isLoading }
                  onClick={ () => {
                    finishTrialActions.finishTrial();
                  } }
                >
                  Finish trial and pay
                </Button>
                <span className="text-sm text-gray-200">
                  To download your full document now
                </span>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
