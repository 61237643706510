
import { ReactElement, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
// types
import PersonInterface from '../../types/PersonInterface';
import SanitizedFormData from '../../../core/types/SanitizedFormData';

// components
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import Form from '../../components/Form';
import PercentageField from '../../components/PercentageField';
import Translation from '../../components/Translation';
import HorizontalRule from '../../components/HorizontalRule';
import { RadioGroup } from '../../components/Radio';
import Fieldset from '../../components/Fieldset';

// hooks
import useBackstack from '../../hooks/useBackstack';
import useTranslations from '../../hooks/useTranslations';

// utils
import Person from '../../core-data-service/models/Person';
import { stringToBoolean } from '../../../core/utils/booleanUtils';
import { INITIAL_DISTRIBUTION_AGE, MODAL_TRANSITION_DURATION_MS, SECONDARY_DISTRIBUTION_AGE } from '../../../core/constants';
import { centsToUSDollars } from '../../utils/currencyUtils';
import { generateQueryString } from '../../utils/urlUtils';
import { DOCUMENTS } from '../../core-data-service/models/Document';

// redux
import entitlementsSelector from '../../store/selectors/entitlementsSelector';
import { makeSnack } from '../../store/reducers/snackbarReducer';
import { useDispatch, useSelector } from '../../store';
import { fetchInheritances, patchInheritance, postInheritance, deleteInheritance } from '../../store/reducers/inheritancesReducer';
import userPersonSelector from '../../store/selectors/userPersonSelector';
import { showContextualModal } from '../../store/reducers/modalReducer';

// routes
import { checkout, trust as trustRoute, will as willRoute } from '../../../core/routes';

export type InheritanceDocumentContext = 'will' | 'trust';

export default function InheritanceShareForm(): ReactElement{

  const dispatch = useDispatch();
  const { push } = useHistory();
  const { getTranslation } = useTranslations();
  const { trust: { isEnabled: isTrustEnabled, upgrades: trustUpgrades } } = useSelector( entitlementsSelector );

  // Get person, document inheritance came from
  const { id, documentContext } = useParams<{ id: PersonInterface['id']; documentContext: InheritanceDocumentContext}>();

  const _person = useSelector( state => state.people.data )?.find( person => person.id === id );
  const person = _person && new Person( _person );
  const personFirstName = person?.firstName || '';
  const config = useSelector( state => state.config.data );

  const stateOfResidency = useSelector( userPersonSelector )?.address?.state;

  const isRenderedInTrust = documentContext === 'trust';

  const shouldShowDistributionOptions = !person?.isSpouse && isRenderedInTrust;
  // if the trust is not unlocked, we want to incentivize the user to unlock it
  const shouldShowDistributionUpsell = !isTrustEnabled && !person?.isSpouse && !isRenderedInTrust;
  // spouses always receive their distribution immediately
  const shouldShowImmediateDistribution = person?.isSpouse && isRenderedInTrust;

  const shouldRenderTimedDistribution = shouldShowDistributionOptions ||  shouldShowDistributionUpsell || shouldShowImmediateDistribution;

  const inheritance = useSelector( state => state.inheritances.data.find( inheritance => inheritance.person_id === _person?.id ));

  const { register, setValue, getValues, handleSubmit, formState, watch } = useForm({
    shouldUnregister: false,
    defaultValues: {
      controlled: 'true',
      share: inheritance?.share || 0,
      trustUpsell: 'false',
      timedDistribution: `${inheritance?.distributions[0]?.share ? 'true' : 'false' }`,
      initialPercentage: ( inheritance?.distributions[0]?.share || 30 ),
    },
  });

  const [ isPercentageModalOpen, setIsPercentageModalOpen ] = useState( false );
  const { errors } = formState;
  const useFormProps = { register, setValue, getValues, errors };


  // instantiate our watchers
  const upsellRadio = watch( 'trustUpsell' );
  const initialPercentageField = Number( watch( 'initialPercentage' ));
  // if someone changes the trust upsell value, render the contextual modal
  useEffect(()=> {
    if( stringToBoolean( upsellRadio )) {
      setValue( 'trustUpsell', 'false' );
      dispatch( showContextualModal({
        title: getTranslation( 'products.will.upsell.trust.title' ),
        description: getTranslation( 'products.will.upsell.trust.body' ),
        confirmLabel: getTranslation( 'products.will.upsell.trust.distribution.button1' ),
        dismissLabel: getTranslation( 'products.will.upsell.trust.button2' ),
        onConfirm: () => {
          push({
            pathname: checkout.get(),
            // user should end up at the trust document after checkout
            search: generateQueryString({ document: DOCUMENTS.trust }),
          });
        },
        confirmButtonTheme: 'tertiary',
      }));
    }
  }, [ upsellRadio, getTranslation, setValue, dispatch, push ]);

  // routing
  const { goBack } = useBackstack( isRenderedInTrust ? trustRoute.get() : willRoute.get());

  // @TODO - don't love the setTimeout here
  const [ isOpen, setIsOpen ] = useState( true );
  const onClose = () => {
    setIsOpen( false );
    setTimeout(() => goBack(), MODAL_TRANSITION_DURATION_MS );
  };

  const [ isPending, setIsPending ] = useState( false );

  /**
   * Do this after successful request
   */
  const onSuccess = () => {
    onClose();
  };

  const onFailure = () => setIsPending( false );

  useEffect(() => {
    dispatch( fetchInheritances());
  }, [ dispatch ]);

  /*
   * Form submit function
   * @param data form data
   */
  const onSubmit: SubmitHandler<SanitizedFormData> = data => {
    // if person is spouse check data that value is greater than 0
    setIsPending( true );
    const value = Number( data.share );
    const initialDistributionPercentage = stringToBoolean( data.timedDistribution || '' ) ? initialPercentageField : 0;

    // limit values
    if ( value > 100 || value < 0 ) {
      onFailure();
      return dispatch( makeSnack({ message: 'You must set a value between 0 and 100', theme: 'error' }));
    }

    if ( person?.relationship.isSpouse ) {
      // spouse requires minimum value based on state
      if( stateOfResidency ){
        const minValue = parseFloat( config?.config?.states[stateOfResidency]?.spouse_inheritance_floor ?? 0 );
        if(  value < minValue ){
          onFailure();
          return dispatch( makeSnack({ message: getTranslation( 'error.spouse.share.under.minimum', [ `${minValue}` ]), theme: 'error' }));
        }
      }
    }

    if( !!inheritance ) {
      const inheritanceData = {
        id: inheritance.id,
        share: value,
        onSuccess: () => {
          onSuccess();
          // render disinherit snack if we are "disinheriting"
          value === 0 && dispatch( makeSnack({ message: getTranslation( 'banner.disinherit.confirmation', [ personFirstName ]), theme: 'error' }));
        },
        onError: () => {
          onFailure();
          makeSnack({ message: 'There was a problem modifying the inheritance, please try again.', theme: 'error' });
        },
      };
      // only apply the distribution if it's shown
      shouldShowDistributionOptions && Object.assign( inheritanceData, { initialDistributionPercentage });

      dispatch( patchInheritance( inheritanceData ));
    } else {
      if ( !_person ) {
        return;
      }
      dispatch( postInheritance({ person_id: _person?.id , share: value, onSuccess }));
    }
  };

  /**
   * Update form values after person is fetched from API
   */
  useEffect(() => {
    if( !!inheritance ){
      setValue( 'share', inheritance.share );
    }
  }, [ inheritance, setValue ]);

  const disinherit = (): void => {
    dispatch( showContextualModal({
      title: getTranslation( 'alert.heir.disinherit.confirmation.trust.title', [ personFirstName ]),
      description: getTranslation( 'alert.heir.disinherit.will.body' ),
      confirmLabel: getTranslation( 'alert.heir.disinherit.will.firstbutton.title', [ personFirstName ]),
      onConfirm: () => {
        onSubmit({
          share: '0',
        });
      },
      dismissLabel: getTranslation( 'alert.heir.disinherit.will.secondbutton.title' ),
      titleClassName: 'text-red',
      confirmButtonTheme: 'danger',
    }));

  };

  const notifyPersonInheritanceDeleted = () => {
    onClose();
    dispatch( makeSnack({ message: `${personFirstName} has been removed.` }));
  };

  const remove = (): void => {
    if( !inheritance ) {
      return;
    }
    dispatch( deleteInheritance({ id: inheritance.id, onSuccess: notifyPersonInheritanceDeleted }));
  };

  const shouldShowDisinherit =
    !person?.relationship.isSpouse &&
    person?.relationship.isHeir &&
    ( inheritance?.share !== 0 );

  const shouldShowRemove =
    !person?.relationship.isSpouse &&
    !person?.relationship.isHeir &&
    ( inheritance?.share !== 0 ); // not sure this is possible?

  return (
    <>
      <Modal
        onClose={ onClose }
        isOpen={ isOpen }
        toggleModal={ onClose }
      >
        <div className="flex items-start justify-between">
          <h1 className="text-4xl capitalize">
            <Translation translationKey="screen.heir.change.inheritance.title" dynamicStringValues={ [ personFirstName ] }/>
          </h1>
          { shouldShowRemove &&
        <Button
          className="
          absolute
          top-8
          right-8
          z-10
          md:relative
          md:top-0
          md:right-0
          md:z-0
          "
          theme="danger-ghost"
          onClick={ remove }
        >
          <Translation
            translationKey={ 'screen.heir.change.inheritance.remove.heir' }
          />
        </Button>
          }
        </div>

        <Form
          className="flex flex-col flex-1 justify-between"
          handleSubmit={ handleSubmit }
          onSubmit={ onSubmit }
        >

          <div className="flex flex-col">
            <PercentageField
              className="mb-8"
              label={ <Translation translationKey="screen.heir.change.inheritance.label"/> }
              name="share"
              { ...useFormProps }
            />
            { shouldShowDisinherit &&
            <Button
              className="-mt-8 mb-4"
              type="button"
              theme="danger-alt"
              onClick={ disinherit }
            >
              <div className="flex justify-between">
                <span>
                  <Translation translationKey="tile.heir.share.inheritance.exlude.label" dynamicStringValues={ [ personFirstName ] }/>
                </span>
                <span>
                  <Translation translationKey="tile.heir.share.inheritance.learnmore.label" />
                </span>
              </div>
            </Button>
            }
            { shouldRenderTimedDistribution && <>
              <HorizontalRule />
              { shouldShowDistributionOptions && <RadioGroup
                { ...useFormProps }
                items={
                  [
                    {
                      value: 'true',
                      label:
                  <div className="flex flex-col align-start">
                    <Translation translationKey="products.heirs.inheritances.timing.options.paid_in_stages"
                      dynamicStringValues={ [
                        personFirstName,
                        `${INITIAL_DISTRIBUTION_AGE}`,
                        `${INITIAL_DISTRIBUTION_AGE}`,
                        personFirstName,
                        `${initialPercentageField}%`,
                        `${100 - Number( initialPercentageField )}%`,
                        `${SECONDARY_DISTRIBUTION_AGE}`,
                      ] }
                    />
                    <button
                      className="text-left text-forest font-black pt-2 focus:outline-none"
                      onClick={ e => {
                        e.preventDefault();
                        setIsPercentageModalOpen( true );
                        if( getValues( 'timedDistribution' ) === 'false' ) {
                          setValue( 'timedDistribution', 'true' );
                        }
                      } }
                    >
                      <Translation translationKey="(button)edit"/>
                    </button>
                  </div>,
                    },
                    {
                      value:'false',
                      label: <Translation translationKey="products.heirs.inheritances.timing.options.paid_at_age" dynamicStringValues={ [ personFirstName, `${SECONDARY_DISTRIBUTION_AGE}` ] }/>,
                    },
                  ]
                }
                itemsFullWidth={ true }
                itemsClassName={ 'bg-moss p-4 first:-mb-4 rounded border border-forest' }
                label={ <Translation translationKey="products.heirs.inheritances.timing.title"/> }
                name="timedDistribution"
              />}
              { shouldShowDistributionUpsell &&
            <RadioGroup
              { ...useFormProps }
              items={
                [
                  { value: 'false',
                    label: <Translation translationKey="products.heirs.inheritances.timing.options.age18" dynamicStringValues={ [ personFirstName ] }/>,
                  },

                  { value: 'true',
                    label: <div className="flex flex-col">
                      <Translation translationKey="products.heirs.inheritances.timing.options.trust" dynamicStringValues={ [ personFirstName ] }/>
                      <span className="text-forest font-black pt-2"><Translation translationKey="products.will.upsell.trust.heirs.button1" dynamicStringValues={ [ centsToUSDollars( trustUpgrades[0]?.price ) ] }/></span>
                    </div>,
                  },
                ]
              }
              label={ <Translation translationKey="products.heirs.inheritances.timing.title"/> }
              name="trustUpsell"
              itemsFullWidth={ true }
              itemsClassName={ 'bg-moss p-4 first:-mb-4 rounded border border-forest' }
            />
              }
              { shouldShowImmediateDistribution && <>
                <span className="mb-2 font-bold text-black"><Translation translationKey="products.heirs.inheritances.timing.title"/></span>
                <div
                  className="bg-moss border border-forest px-6 py-5 rounded max-w-md"
                >
                  <Translation translationKey="products.heirs.inheritances.timing.options.paid_now" dynamicStringValues={ [ personFirstName ] }/>
                </div>
              </>}
            </>}
          </div>
          <div className={ `flex justify-end ${shouldRenderTimedDistribution ? 'mt-8' : ''}` }>
            <Button type="submit" isPending={ isPending }>
              <Translation translationKey="screen.heir.change.inheritance.button.apply"/>
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        isOpen={ isPercentageModalOpen }
        onClose={ () => setIsPercentageModalOpen( false ) }
      >
        <Fieldset
          title="Paid In Stages"
        >
          <>
            <HorizontalRule />
            <PercentageField
              { ...useFormProps }
              name="initialPercentage"
              label={ <Translation translationKey="products.heirs.inheritances.timing.input.label" dynamicStringValues={ [ `${INITIAL_DISTRIBUTION_AGE}`, personFirstName ] }/> }
              showLabel
              normalize={ value => {
                if( value ) {
                  // try to be smart and set the "toggle" on previous view to false if you change to 0
                  Number( value ) === 0
                    ? setValue( 'timedDistribution', 'false' )
                    : setValue( 'timedDistribution', 'true' );
                  if ( Number( value ) > 100 ) {
                    return '100';
                  } else if ( Number( value ) < 0 ) {
                    return '0';
                  } else {
                    return value;
                  }
                } else {
                  return '';
                }
              } }
            />
            <Translation translationKey="products.heirs.inheritances.timing.remainder.label" dynamicStringValues={ [ personFirstName, `${100 - initialPercentageField}%`,`${SECONDARY_DISTRIBUTION_AGE}` ] }/>
          </>
        </Fieldset>
        <Button onClick={ () => {
          setIsPercentageModalOpen( false );
        } }>
          <Translation translationKey="(button)update" />
        </Button>
      </Modal>
    </>
  );
}
