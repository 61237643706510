import { useState } from "react";
import WebClientRequest from "../core-data-service/WebClientRequest";
import { AxiosError } from "axios";
import { v4 as uuid } from "uuid";
import log from "../utils/log";
import analytics from "../utils/analytics";

interface UseFinishTrial {
  finishTrial: () => void;
  isSuccess: boolean;
  error: string | null;
  isLoading: boolean;
}

const ORIGINAL_DOCUMENT_URL_KEY = "original_document_url";
const STATE_KEY = "state";

export const getCurrentPaymentRedirectState = (): {
  originalDocumentUrl: string;
  state: string;
} => {
  const originalDocumentUrl =
    localStorage.getItem(ORIGINAL_DOCUMENT_URL_KEY) ?? "";
  const state = localStorage.getItem(STATE_KEY) ?? "";
  return { originalDocumentUrl: atob(originalDocumentUrl), state };
};

export const createAndSetPaymentRedirectState = (): {
  state: string;
} => {
  const uniqueId = uuid();
  localStorage.setItem(ORIGINAL_DOCUMENT_URL_KEY, btoa(window.location.href));
  localStorage.setItem(STATE_KEY, uniqueId);
  return { state: uniqueId };
};

export const clearPaymentRedirectState = (): void => {
  localStorage.removeItem(ORIGINAL_DOCUMENT_URL_KEY);
  localStorage.removeItem(STATE_KEY);
};

const validDomains = ["ethoslife.com", "ethos.com"];

const isURLValid = (url: URL, validDomains: string[]) => {
  const hostParts = url.hostname.split(".");
  const domainAndTld = hostParts.slice(-2).join(".");
  return validDomains.some((domain) => domain === domainAndTld);
};

const generateRedirectUrl = (url: URL) => {
  const { state } = createAndSetPaymentRedirectState();
  const successUrl = new URL(window.location.origin);
  successUrl.pathname = "/payment-succeeded";
  successUrl.searchParams.append("state", state);
  const backUrl = new URL(window.location.origin);
  backUrl.pathname = "/payment-cancelled";
  backUrl.searchParams.append("state", state);
  url.searchParams.append("success_url", successUrl.toString());
  url.searchParams.append("back_url", backUrl.toString());
  return url;
};

function useFinishTrial(): UseFinishTrial {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const finishTrial = () => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    WebClientRequest.post("/v1/finish-trial")
      .then((response) => {
        const { success, redirect_url } = response.data.data;
        if (success) {
          analytics.track("finish_trial_success", {});
          setSuccess(true);
        } else if (redirect_url) {
          const url = new URL(redirect_url);
          if (!isURLValid(url, validDomains)) {
            throw new Error(`Invalid redirect url ${url.hostname}`);
          }
          const redirectUrl = generateRedirectUrl(url);
          analytics.track("finish_trial_redirect", {
            redirect_url: redirectUrl.toString(),
            current_url: window.location.href,
            state: getCurrentPaymentRedirectState().state,
          });
          window.location.replace(redirectUrl.toString());
        } else {
          analytics.track("finish_trial_error", {
            status: "unknown_flow",
            error: response.data,
          });
          log.error(
            `Unknown flow found during finish trial: ${JSON.stringify(
              response.data
            )}`
          );
          throw new Error("Unknown flow found during finish trial");
        }
      })
      .catch((e: string) => {
        setError(e);
        analytics.track("finish_trial_error", {
          status: "fail_api",
          error: e,
        });
      })
      .finally(() => setLoading(false));
  };

  return { isSuccess, isLoading, error, finishTrial };
}

export default useFinishTrial;
